import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Button } from "../../components";
import { Table } from "./Table";
import { Grid } from "@mui/material";
import { useNavigateBack } from "../../components";
import { getDeptStatusStats, getTypeStatusStats, docActions, getDocStatusList, getStatusStats } from "../../store";
import "./style.scss";

const StatsCards = [
  { label: 'Published', attribute: 'Published' },
  { label: 'Approved', attribute: 'Approved' },
  { label: 'Review', attribute: 'Review' },
  { label: 'Draft', attribute: 'Draft' },
]
export const DocumentsStatsScreen = (props) => {
  const dispatch = useDispatch()
  const navigateBack = useNavigateBack()
  const statusList = useSelector(getDocStatusList)
  const deptVstatus = useSelector(getDeptStatusStats)
  const typeVstatus = useSelector(getTypeStatusStats)
  const statusWise = useSelector(getStatusStats)
  useEffect(() => {
    if (statusList) {
      console.log(statusList);
      dispatch(docActions.fetchDocStats())
    } else {
      dispatch(docActions.fetchDocStatusList())
    }
  }, [statusList])
  return (
    <div className='col w-100 h-100 o-hide doc-stats'>
      <div className='row header'>
        <Button
          font='f7 bold'
          className='row btn-back'
          onClick={navigateBack}
          label='Documentation Status' icon="icon-back"
          variant='lite' color='rgba(0, 0, 0, 0.85)' iconColor='c00085' />
      </div>
      <div className='col f-rest o-hide content'>
        {
          Array.isArray(statusList) && Boolean(statusWise) &&
          <div className='col w-100 h-100 o-hide'>
            <div className='row'>
              {
                statusList.map((_) => {
                  return (
                    <div className='col stats-card' key={_.id}>
                      <h6 className='f9 c00045 line-22 caps'>{_.label}</h6>
                      <span className='exo2 f1 c00085 num'>{statusWise[_.id] || 0}</span>
                    </div>
                  )
                })
              }
            </div>
            <div className='row f-rest o-hide'>
              <Grid classes={{ root: 'w-100 h-100 o-hide' }} container columnSpacing={16}>
                <Grid classes={{ root: 'col h-100 o-hide' }} item xs={12} sm={12} md={6} lg={6} >
                  <Table heading='Department vs Status' className='f-rest' rows={deptVstatus} />
                </Grid>
                <Grid classes={{ root: 'col h-100 o-hide' }} item xs={12} sm={12} md={6} lg={6} >
                  <Table heading='Type vs Status' className='f-rest' rows={typeVstatus} />
                </Grid>
              </Grid>
            </div>
          </div>
        }
      </div>
    </div>
  )
}
import React from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { Button } from "../Button";
import "./style.scss";

export const TransparentDialog = (props) => {
  return (
    <Dialog open classes={{ paper: `transparent-dialog ${props.className || ''}`, container: `${props.containerClassName || ''}` }}>
      <DialogContent classes={{ root: 'dialog-container' }}>
        {props.children}
      </DialogContent>
    </Dialog>
  )
}
export const FormDialog = (props) => {
  const { title, leftBtn, rightBtn, actions, titleClass, TitleComponent } = props
  return (
    <Dialog open onClose={props.onClose} classes={{ paper: `form-dialog o-hide ${props.className || ''}` }}>
      <DialogContent classes={{ root: 'col dialog-container o-hide' }}>
        {
          Boolean(title) ?
            <div className='col title-sec'>
              <h6 className={`f6 ${titleClass || 'med'}`}>{title}</h6>
            </div>
            :
            Boolean(TitleComponent) ?
              <TitleComponent />
              : null
        }
        <div className='col oy-auto'>
          {props.children}
        </div>
      </DialogContent>
      <DialogActions className='dialog-actions pad'>
        {
          Boolean(leftBtn) &&
          <Button {...leftBtn} />
        }
        {
          Boolean(rightBtn) &&
          <Button {...rightBtn} />
        }
        {
          Array.isArray(actions) &&
          actions.map((_, i) => {
            return <Button {..._} key={i} />
          })
        }
      </DialogActions>
    </Dialog>
  )
}
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { FormDialog, AutoComplete, TransparentDialog, CreateDocLoadingImg, TextInput } from "../../components";
import { getDepartments, getBlueprints, getDocTemplates, getDocTypes, docActions, getCurrentAPI, DocEndPoints } from "../../store";
import "./style.scss";

const FormCommonFields = [
  { label: 'Department', placeholder: 'Department', attribute: 'department_name' },
  { label: 'Type', placeholder: 'Type', attribute: 'doc_type' },
]
const Fields = [
  { label: 'Document ID', placeholder: 'ID', attribute: 'doc_id', width: 2, disabled: true },
  { label: 'Version', placeholder: 'Version', attribute: 'version', width: 2 },
  { label: 'Classification', placeholder: 'Classification', attribute: 'classification', type: 'select', width: 3 },
  { label: 'Department', placeholder: 'Department', attribute: 'department_name', type: 'select', width: 3 },
  { label: 'Type', placeholder: 'Type', attribute: 'doc_type', type: 'select', width: 2 },
]

const DocumentField = { label: 'Document', placeholder: 'Document', attribute: 'blueprint_id' }

const TitleField = { label: 'Title', placeholder: 'Title', attribute: 'name', type: 'text' }

const Loading = () => {
  return (
    <TransparentDialog className='create-loading'>
      <CreateDocLoadingImg className='loader' />
    </TransparentDialog>
  )
}
const getFormData = (fields, document) => {
  const formData = {};
  fields.forEach(({ attribute }) => {
    formData[attribute] = (document && document[attribute]) || ''
  })
  return formData
}
export const CreateDocumentDialog = (props) => {
  const { isEdit, document, open } = props
  const dispatch = useDispatch()
  const depts = useSelector(getDepartments)
  const docTypes = useSelector(getDocTypes)
  const blueprints = useSelector(getBlueprints)
  const currentAPI = useSelector(getCurrentAPI)
  const [state, setState] = useState({ formData: {} })
  useEffect(() => {
    if (document && isEdit & open) {
      setState((_) => ({ ..._, formData: getFormData(getFields(), document) }))
    }
  }, [open])
  const getOptions = (attribute) => {
    switch (attribute) {
      case 'department_name': return depts;
      case 'doc_type': return docTypes;
      case 'blueprint_id': return blueprints;
    }
  }
  const getFields = () => {
    const fields = [...FormCommonFields]
    if (isEdit) {
      fields.push(TitleField)
    } else if (Array.isArray(blueprints)) {
      fields.push(DocumentField)
    }
    return fields
  }
  const handleFormChange = (e) => {
    const { name, value } = e.target
    const formData = { ...state.formData };
    formData[name] = value;
    if (
      (name === 'department_name' || name === 'doc_type') &&
      (formData.department_name && formData.doc_type)
    ) {
      dispatch(docActions.setBlueprints([]))
      formData.blueprint_id = null;
      dispatch(docActions.fetchBlueprintsByDeptType({
        department_name: formData.department_name.id,
        doc_type: formData.doc_type.id,
      }))
    }
    setState((_) => ({ ..._, formData: { ...formData } }))
  }
  const handleCreateDoc = () => {
    if (isEdit) {
      let body = {}
      getFields().forEach(({ attribute }) => {
        const fieldVal = state.formData[attribute]
        body[attribute] = (typeof fieldVal === "object") ? fieldVal.id : fieldVal;
      })
      dispatch(docActions.updateDocument({ id: document.id, document: body }))
    } else {
      const { blueprint_id } = state.formData;
      dispatch(docActions.createDocFromBlueprint({ blueprint_id: blueprint_id.id }))
      dispatch(docActions.setBlueprints([]))
    }
    handleClose()
  }
  const enableCreate = () => {
    const { department_name, doc_type, blueprint_id, name } = state.formData
    return Boolean(department_name && doc_type && (isEdit ? name : blueprint_id))
  }
  const handleClose = () => {
    setState((_) => ({ ..._, formData: {} }))
    props.onClose && props.onClose();
  }
  const FormFields = getFields()
  return (
    <React.Fragment>
      {
        props.open &&
        <FormDialog
          onClose={handleClose} className="create-doc"
          rightBtn={{ label: isEdit ? 'Save' : "Create New", disabled: !enableCreate(), onClick: handleCreateDoc }}
          title={isEdit ? 'Edit Document Details' : 'Create new Document (from Blueprint)'} >
          <div className='col content'>
            {
              FormFields.map(({ attribute, ...rest }) => {
                const p = {
                  value: state.formData[attribute] || '', required: true, key: attribute,
                  className: 'w-100', name: attribute, onChange: handleFormChange, showColon: true,
                  ...rest
                }
                return (
                  rest.type === 'text' ?
                    <TextInput {...p} />
                    :
                    <AutoComplete
                      {...p}
                      options={getOptions(attribute)} />
                )
              })
            }
          </div>
        </FormDialog>
      }
      {
        currentAPI === DocEndPoints.CREATE_DOC && !isEdit &&
        <Loading />
      }
    </React.Fragment>
  )
}
export const CreateAllDocuments = (props) => {
  const currentAPI = useSelector(getCurrentAPI)
  const dispatch = useDispatch()
  const handleCreate = () => {
    dispatch(docActions.createAllDocs())
    props.onClose && props.onClose();
  }
  return (
    <React.Fragment>
      {
        props.open &&
        <FormDialog
          onClose={props.onClose}
          title='Create all Documents?'
          className="create-doc all"
          rightBtn={{ label: 'Create', color: '#FE3333', onClick: handleCreate }}>
          <div className='col content'>
            <p className='f8 c777 line-22 warn'>All the required Documents will be created (from the available Blueprints).<br />
              This action can be performed <span className='bold'>only once!</span>. All Documents will be the first version & their status will be “Draft”. Created Documents must be reviewed & approved before they can be published.</p>
          </div>
        </FormDialog>
      }
      {
        currentAPI === DocEndPoints.CREATE_DOC &&
        <Loading />
      }
    </React.Fragment>
  )
}
import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../common";

const initialState = {
  currentUser: null,
  inActiveUsers: {},
  activeUsers: {}
}

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    getCurrentUser: (state) => {
      state.currentUser = null
    },
    login: (state, action) => {
      state.logInStatus = 'wait';
      state.loggedOut = false;
    },
    loginBytGuid: () => { },
    logout: (state, action) => { },
    validateToken: (state) => { },
    logInSuccess: (state, action) => {
      state.currentUser = action.payload.current_ent_usr;
      state.orgLogo = action.payload.ent_org_logo;
      state.logInStatus = action.payload.status ? 'success' : "failed";
    },
    logInSuccessClear: (state, action) => {
      state.logInStatus = null
    },
    logInError: (state, action) => {
      state.logInStatus = 'Error';
    },
    logoutSuccess: (state, action) => {
      state.isLoggedOut = true;
    },
    clear: (state) => {
      state.isLoggedOut = false;
      state.currentUser = null;
      state.logInStatus = null;
      state.inActiveUsers = {};
      state.activeUsers = {}
    }
  }
})
export const userActions = userSlice.actions;

ReducerRegistry.register("user", userSlice.reducer)
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Menu, DataTable, Button, LoadingOval, OvalLoading } from "../../components";
import { CreateDocumentDialog, UploadDocumentDialog, CreateAllDocuments, CreateDocPresentation } from "../DocumentDialogs";
import { docActions, getDocuments, getDepartments, getDocTypes, getDocStatusList, isViewer, getCurrentUser, getDeleteStatus } from "../../store";
import "./style.scss";
import { useEffect } from 'react';

const DocumentColumns = [
  { title: 'Version', dataIndex: 'version', key: 'version', width: '100px', className: 'exo2 f9' },
  { title: 'ID', dataIndex: 'doc_id', key: 'doc_id', width: '100px', className: 'exo2 f9 text-center', sort: true},
  { title: 'Document', dataIndex: 'name', key: 'name', width: '42%', render: "link", search: true, className: 'exo2 f9' },
  { title: 'Department', dataIndex: 'department_name', key: 'department_name', width: '10%', search: true, className: 'exo2 f9 text-center' },
  { title: 'Status', dataIndex: 'status', key: 'status', width: '11%', render: "color-code", sort: true, className: 'exo2 f9' },
  { title: 'Classification', dataIndex: 'classification', key: 'clasification', width: '12%', render: "color-code", className: 'exo2 f9' },
  { title: 'Type', dataIndex: 'doc_type', key: 'doc_type', width: '10%', sort: true, className: 'exo2 f9 caps' },
  { title: 'Last Updated', dataIndex: 'updated_at', key: 'updated_at', width: '11%', className: 'exo2 f9' },
]

const DocumentMenus = [
  { dialogId: 'createDocPres', label: "Create New (from Presentation)", icon: 'icon-plus-lite', authoring: true },
  { dialogId: 'createDoc', label: "Create New (from Blueprint)", icon: 'icon-plus-lite', authoring: true },
  { dialogId: 'upload', label: "Upload Existing Document(s)", icon: 'icon-upload', authoring: true },
  // { dialogId: 'download', label: "Export (as Encrypted PDF)", icon: 'icon-export', selection: true },
  // { dialogId: 'share', label: "Share (Encrypted PDF by Email)", icon: 'icon-share', selection: true },
  { label: "View Stats", icon: 'icon-stats', isLink: true, to: 'stats' },
  { label: "Archived Documents", icon: 'icon-open-folder', isLink: true, to: 'archives' },
  // { isEmpty: true },
  // { dialogId: 'archive', label: "Archive", icon: 'icon-open-folder', color: 'cFE3333', selection: true },
  // { dialogId: 'delete', label: "Delete", icon: 'icon-delete', color: 'cFE3333', selection: true },
]
const Empty = (props) => {
  const { user } = props
  return (
    <div className='row w-100 v-ctr h-ctr home-create' style={{ height: props.height }}>
      {
        isViewer(user) ?
          <h6 className='f6 exo2 bold c00045'>There are no Documents right now!</h6>
          :
          <React.Fragment>

            <div className='col'>
              <h5 className='f9 hint reg'>There are no Documents right now! <br />You can either create new ones or upload existing ones...</h5>
              <div className='row'>
                <Button label='Create Documents' icon='icon-plus-lite' iconColor='cFFF' onClick={props.onCreateDoc} />
                <Button label='Upload Documents' icon='icon-upload' iconColor='cFFF' onClick={props.onUploadDoc} />
              </div>
            </div>
            <LoadingOval className='oval' />
          </React.Fragment>
      }
    </div>
  )
}

export const DocumentsScreen = (props) => {
  const dispatch = useDispatch()
  const divRef = useRef(null)
  const user = useSelector(getCurrentUser)
  const depts = useSelector(getDepartments)
  const types = useSelector(getDocTypes)
  const status = useSelector(getDocStatusList)
  const deleteStatus = useSelector(getDeleteStatus)
  const documents = useSelector(getDocuments.bind(null, isViewer(user)))
  const [state, setState] = useState({ showDocsMenu: null, showDialog: '', selectedRows: [] })
  useEffect(() => {
    if (Array.isArray(depts) && Array.isArray(types) && Array.isArray(status) && !deleteStatus) {
      dispatch(docActions.fetchDocuments())
    }
  }, [depts, types, status])
  const handleDocsMenu = (e) => {
    setState((_) => ({ ..._, showDocsMenu: e.currentTarget }))
  }
  const handleClick = (e, menu) => {
    handleDocsMenu({ currentTarget: null })
    if (menu.dialogId === 'createDocPres') {
      dispatch(docActions.setActivePresentation(null));
    }
    setState((_) => ({ ..._, showDocsMenu: null, showDialog: menu.dialogId }))
  }
  const handleDialogClose = () => {
    dispatch(docActions.setNewDocumentIds('clear'))
    setState((_) => ({ ..._, showDialog: '' }))
  }
  const handleDocumentSelection = (selectedRows) => {
    setState((_) => ({ ..._, selectedRows: selectedRows }))
  }
  const getMenus = () => {
    return DocumentMenus.filter((_) => {
      return isViewer(user) ? !_.authoring : true
    })
  }
  useEffect(() => {
    if (divRef.current) {
      setState((_) => ({ ..._, height: divRef.current.clientHeight - 150 }))
    }
  }, [])
  return (
    <div className='col w-100 h-100 o-hide'>
      <div className='row docs-header h-btn'>
        <h4 className='exo2 f4 med'>Documents</h4>
        {
          !isViewer(user) &&
          <Button className='col v-ctr h-ctr dot-menu btn-menu' icon="f9 icon-dot c00085" variant='lite' onClick={handleDocsMenu} />
        }
        <Menu
          menuItems={getMenus()}
          anchorEl={state.showDocsMenu}
          onMenuClick={handleClick}
          onClose={() => handleDocsMenu({ currentTarget: null })} />
      </div>
      <div className='col f-rest doc' ref={divRef}>
        <DataTable
          noSelection
          Columns={DocumentColumns}
          rows={documents || []}
          EmptyComponent={(() => {
            return (
              Array.isArray(documents) ?
                <Empty
                  user={user}
                  height={state.height}
                  onCreateDoc={(e) => handleClick(e, { dialogId: 'createall' })}
                  onUploadDoc={(e) => handleClick(e, DocumentMenus[2])}
                />
                :
                <OvalLoading />
            )
          })}
          onSelectionChange={handleDocumentSelection} />
      </div>
      <CreateDocumentDialog
        open={state.showDialog === 'createDoc'}
        onClose={handleDialogClose}
      />
      <CreateAllDocuments
        open={state.showDialog === 'createall'}
        onClose={handleDialogClose}
      />
      {
        state.showDialog === 'upload' &&
        <UploadDocumentDialog onClose={handleDialogClose} />
      }
      {
        state.showDialog === 'createDocPres' &&
        <CreateDocPresentation onClose={handleDialogClose} />
      }

      {/* {
        state.showDialog === 'download' &&
        <ExportDocumentDialog
          onClose={handleDialogClose}
          onDownload={handleDownloadDocs} />
      }
      {
        state.showDialog === 'share' &&
        <ShareDocumentDialog
          onClose={handleDialogClose}
          onShare={handleShareDocuments} />
      } */}
    </div>
  )
}
import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { FormDialog, AutoComplete, TextInput, PageSections, OvalLoading } from "../../components";
import { getDepartments, getDocTypes, docActions, getDocClassifications, getDocStatusListNonArchive, getNewDocumentsIds, getActivePresentation } from "../../store";
import "./style.scss";

const Fields = [
  { label: 'Type', placeholder: 'Type', attribute: 'doc_type', type: 'select', width: 2 },
  { label: 'Document ID', placeholder: 'ID', attribute: 'doc_id', width: 2 },
  { label: 'Version', placeholder: 'Version', attribute: 'version', width: 2 },
  { label: 'Classification', placeholder: 'Classification', attribute: 'classification', type: 'select', width: 3 },
  { label: 'Department', placeholder: 'Department', attribute: 'department_name', type: 'select', width: 3 },
]
const isSingledFormFilled = (formData, enable) => {
  if (!formData.name || !formData.status) {
    enable = false;
  } else {
    for (let j = 0; j < Fields.length; j++) {
      const { attribute } = Fields[j]
      if (!formData[attribute]) {
        enable = false;
        break
      }
    }
  }
  return enable;
}
const getFormData = (formData, file) => {
  const entry = new FormData()
  if (file) {
    entry.append('document', file)
  }
  entry.append('name', formData.name)
  entry.append('status', formData.status.id)
  Fields.forEach((field) => {
    let value = formData[field.attribute];
    value = (typeof value === "object") ? value.id : value;
    entry.append(field.attribute, value)
  })
  return entry
}
const FileCard = (props) => {
  const { isEdit, isUpload } = props;
  const statusList = useSelector(getDocStatusListNonArchive)
  return (
    <div className={`row file-card h-btn ${isUpload ? '' : 'non-file'}`}>
      <div className='row f-rest'>
        {
          Boolean(isUpload) && <i className='icon-file-pdf icon c00085' />
        }
        {
          isEdit ?
            <div className='row f-rest'>
              <TextInput
                value={props.name}
                className='doc-input'
                name="name" label='Document' required
                onChange={props.onChange} />
              <AutoComplete
                disabled
                value={props.status}
                className='f-rest'
                name="status" label='Status' options={statusList}
                required onChange={props.onChange} />
            </div>
            :
            <h6 className='f9 reg c0133CC'>{props.name}</h6>
        }
      </div>
      {
        Boolean(isUpload) &&
        <button className='btn btn-delete' onClick={props.onDelete}>
          <i className='f9 icon-delete c00045' />
        </button>
      }
    </div>
  )
}
const UploadDetailedForm = (props) => {
  const { formData, onFormChange, onRemove, isUpload, fields } = props;
  const depts = useSelector(getDepartments)
  const docTypes = useSelector(getDocTypes)
  const docClass = useSelector(getDocClassifications);
  const getOptions = (attribute) => {
    switch (attribute) {
      case 'classification': return docClass;
      case 'department_name': return depts;
      case 'doc_type': return docTypes;
      default: return []
    }
  }
  return (
    <div className='col'>
      <FileCard
        isEdit
        isUpload={isUpload}
        name={formData.name}
        status={formData.status}
        onChange={onFormChange}
        onDelete={onRemove} />
      <Grid container columnSpacing={1} classes={{ root: 'file-form' }}>
        {
          fields.map((_) => {
            const p = { ..._, name: _.attribute, required: true, direction: 'column' }
            p.value = formData[_.attribute];
            return (
              <Grid item key={_.attribute} sm={6} xs={6} md={_.width} lg={_.width}>
                {
                  _.type === 'select' ?
                    <AutoComplete
                      {...p}
                      options={getOptions(_.attribute)}
                      onChange={onFormChange} />
                    :
                    <TextInput
                      {...p}
                      onChange={onFormChange} />
                }
              </Grid>
            )
          })
        }
      </Grid>
    </div>
  )
}
export const UploadDocumentDialog = (props) => {
  const dispatch = useDispatch()
  const statusList = useSelector(getDocStatusListNonArchive)
  const docIds = useSelector(getNewDocumentsIds)
  const [state, setState] = useState({ disableNext: true, files: [], tab: 'choose', formData: [] })
  const _ref = useRef(null)
  useEffect(() => {
    if (docIds) {
      const { formData } = state
      for (const index in docIds) {
        if (Object.hasOwnProperty.call(docIds, index)) {
          formData[Number(index)].doc_id = docIds[index]
        }
      }
      setState((_) => ({ ..._, formData: { ...formData } }))
    }
  }, [docIds])
  const handleUploadDocuments = () => {
    if (state.tab === 'choose') {
      const formData = []
      state.files.forEach((_, i) => {
        formData.push({ name: _.name, status: statusList[statusList.length - 1], version: 'v101' })
      })
      setState((_) => ({ ..._, tab: 'upload', disableNext: true, formData: formData }))
    } else {
      const body = []
      state.files.forEach((file, i) => {
        const entry = getFormData(state.formData[i], file)
        body.push(entry)
      })
      dispatch(docActions.uploadDocuments(body))
      props.onClose && props.onClose()
    }
  }
  const handleFileChoose = (e) => {
    if (_ref.current) {
        _ref.current.value = null
        _ref.current.click()
    }
  }
  const handleFileChange = (e) => {
    setState((_) => ({ ..._, files: [...e.target.files], disableNext: false }))
  }
  const isFormFilled = (formData) => {
    let enable = true;
    for (let i = 0; i < state.files.length; i++) {
      if (formData[i]) {
        enable = isSingledFormFilled(formData[i], enable)
      }
      if (!enable) break;
    }
    return enable;
  }
  const handleFormChange = (e, i) => {
    const { name, value } = e.target
    const formData = { ...state.formData };
    formData[i][name] = value;
    if (name === 'doc_type') {
      dispatch(docActions.fetchNewDocId({ doc_type: value.id, index: i }))
    }
    let disableNext = !isFormFilled(formData);
    setState((_) => ({ ..._, formData: { ...formData }, disableNext: disableNext }))
  }
  const handleRemoveFile = (index) => {
    const files = state.files, formData = state.formData;
    files.splice(index, 1);
    let disableNext = files.length === 0;
    if (state.tab !== 'choose' && !disableNext) {
      disableNext = !isFormFilled(formData)
    }
    setState((_) => ({ ..._, files: [...files], formData: Object.values(formData), disableNext: disableNext, tab: files.length === 0 ? 'choose' : _.tab }))
  }
  const handleDragDisable = (e) => {
    e.preventDefault();
    return false
  }
  const handleDragEnter = (e) => {
    e.preventDefault();
    return false
    // console.log('eover', e.dataTransfer.files[0], e.dataTransfer.items[0]);
  }
  const handleDropFiles = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files).filter((_) => _.type === 'application/pdf');
    if (files.length > 0) {
      handleFileChange({ target: { files: files } })
    }
  }
  return (
    <FormDialog
      onClose={props.onClose}
      title='Upload Document(s)'
      className="upload-doc-dialog"
      rightBtn={{
        label: state.tab === 'choose' ? "Next" : 'Upload',
        onClick: handleUploadDocuments,
        disabled: state.disableNext
      }}>
      {
        state.tab === 'choose' &&
        <React.Fragment>
          <div
            className='col w-100 v-ctr h-ctr'
            draggable='true'
            onDragOver={handleDragEnter}
            onDrop={handleDropFiles}
            onDragStart={handleDragDisable}>
            <div className='col files-drop-zone v-ctr h-ctr' onClick={handleFileChoose}>
              <i className='icon-drive c0133CC icon' />
              <h2 className='f8 c00085 reg text'>Click or drag your existing Document(s) here!</h2>
              <h4 className='f9 c00045 reg text'>You can upload one or more PDF file(s)...</h4>
              <h6 className='f11 c00045 reg text'>Be ready to provide Document details for each File... </h6>
              <h6 className='f11 c00045 reg text'> ID, Version, Classification, Department & Type! </h6>
            </div>
            <input type='file' hidden ref={_ref} accept="application/pdf" multiple onChange={handleFileChange} />
          </div>
          {
            state.files.map((_, i) => {
              return (
                <FileCard isUpload document name={_.name} key={i} onDelete={() => handleRemoveFile(i)} />
              )
            })
          }
        </React.Fragment>
      }
      {
        state.tab === 'upload' &&
        state.files.map((_, i) => {
          return (
            <UploadDetailedForm
              key={i}
              isUpload
              formData={state.formData[i]}
              onFormChange={(e) => handleFormChange(e, i)}
              onRemove={() => handleRemoveFile(i)}
              fields={(
                Fields.map((_) => {
                  return { ..._, disabled: (_.attribute === 'doc_id') }
                })
              )}
            />
          )
        })
      }
    </FormDialog>
  )
}

export const CreateDocPresentation = (props) => {
  const dispatch = useDispatch()
  const activePres = useSelector(getActivePresentation)
  const [state, setState] = useState({ formData: { version: 'v101', status: { id: 'draft', label: 'draft' } }, disableNext: true })
  const docIds = useSelector(getNewDocumentsIds)
  useEffect(() => {
    if (activePres === 'NA') {
      props.onClose && props.onClose();
    } else if (activePres === null) {
      dispatch(docActions.fetchActivePresentation())
    }
  }, [activePres])
  useEffect(() => {
    if (docIds) {
      const { formData } = state
      formData.doc_id = docIds[0];
      setState((_) => ({ ..._, formData: { ...formData } }))
      dispatch(docActions.setNewDocumentIds('clear'))
    }
  }, [docIds])
  const handleFormChange = (e) => {
    const { name, value } = e.target
    const formData = { ...state.formData };
    formData[name] = value;
    if (name === 'doc_type') {
      dispatch(docActions.fetchNewDocId({ doc_type: value.id, index: 0 }))
    }
    let disableNext = !isSingledFormFilled(formData, true);
    setState((_) => ({ ..._, formData: { ...formData }, disableNext: disableNext }))
  }
  const handleCreateDocument = () => {
    const formData = getFormData(state.formData);
    let document = {};
    formData.forEach((value, key) => {
      document[key] = value
    })
    dispatch(docActions.createDocFromPresentation({ document, sections: PageSections }));
    props.onClose && props.onClose()
  }
  return (
    <React.Fragment>
      {
        Boolean(activePres === null || activePres === 'NA') ?
          <OvalLoading />
          :
          <FormDialog
            onClose={props.onClose}
            title='Create new Document (from Presentation)'
            className="upload-doc-dialog"
            rightBtn={{
              label: 'Create',
              onClick: handleCreateDocument,
              disabled: state.disableNext
            }}>
            <UploadDetailedForm
              isUpload={false}
              formData={state.formData}
              onFormChange={handleFormChange}
              fields={(
                Fields.map((_) => {
                  return { ..._, disabled: (_.attribute === 'doc_id' || _.attribute === 'version') }
                })
              )}
            />
          </FormDialog>
      }
    </React.Fragment>
  )
}
import React, { useRef } from 'react';
import { Button, DocumenterLogo } from "../../components";
import { getDateInFormat } from "../../store";
import { toPng } from "html-to-image";
import "./style.scss";

const Columns = [
  { label: '', attribute: 'title', headerClass: '', width: '25%' },
  { label: 'OVERALL', attribute: 'overall', headerClass: 'bgOverall cFFF', width: '15%' },
  { label: 'Draft', attribute: 'draft', headerClass: 'status draft cFFF', width: '15%' },
  { label: 'Review', attribute: 'reviewed', headerClass: 'status reviewed c00085', width: '15%' },
  { label: 'Approved', attribute: 'approved', headerClass: 'status approved c00085', width: '15%' },
  { label: 'Published', attribute: 'published', headerClass: 'status published cFFF', width: '15%' }
]

export const Table = (props) => {
  const tableRef = useRef(null)
  const { heading, className, rows } = props
  const getCellClass = (row, col) => {
    if (row === 0 && col === 0) {
      return 'f9 bgOverall cFFF bold tup'
    } else if (col === 0) {
      return 'f9 c000 bold caps'
    } else if (col === 1 || row === 0) {
      return 'f8 c1E686D bold text-center'
    } else {
      return 'f9 text-center'
    }
  }
  const handleExport = () => {
    if (tableRef.current) {
      toPng(tableRef.current, { cacheBust: false })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = heading + ".png";
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  return (
    <div className={`col doc-stats-table o-hide ${className || ''}`}>
      <div className='row h-end'>
        <Button
          label='Export' className='row row-reverse btn-export' icon='icon-export' variant='lite'
          font='f10 med' iconColor='c0133CC' color='#0133CC'
          onClick={handleExport}
        />
      </div>
      <div className='col table-container o-hide' ref={tableRef}>
        <div className='row title-sec h-btn'>
          <h6 className='f6 med c1E686D'>{heading}</h6>
          <h6 className='f11 reg c00085'>as on {getDateInFormat()} </h6>
        </div>
        <div className='col oy-auto' ref={tableRef}>
          <table className='table'>
            <thead>
              <tr>
                {
                  Columns.map((_) => {
                    return (
                      <th className={`cell head-cell ${_.headerClass}`} style={{ width: _.width }}>
                        <span>{_.label}</span>
                      </th>
                    )
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                (rows || []).map((row, i) => {
                  return (
                    <tr key={i}>
                      {
                        Columns.map((col, j) => {
                          return (
                            <td className={`cell ${getCellClass(i, j)}`}>
                              <span>{row[col.attribute]}</span>
                            </td>
                          )
                        })
                      }
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { FormDialog, TextInput } from "../../components";
import { getCurrentUser, getDocumentCredentials } from "../../store";
import "./style.scss";

const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const ShareDocumentDialog = (props) => {
  const user = useSelector(getCurrentUser)
  const [state, setState] = useState({ disableNext: true, credentials: getDocumentCredentials(user.ent_org), recipents: '' })
  const disabledShare = () => {
    if (state.credentials.length === 0 || state.recipents.length === 0) {
      return true
    }
    let recipents = state.recipents.split(',');
    const wrongEmail = recipents.filter((email) => !EmailRegex.test(email));
    return wrongEmail.length > 0
  }
  const handleExportSubmit = (e) => {
    // let recipents = state.recipents.split(',');
    props.onShare && props.onShare(state.credentials, state.recipents)
  }
  return (
    <FormDialog
      onClose={props.onClose}
      title='Share Document(s) by Email'
      className="create-doc export-docs"
      rightBtn={{
        label: 'Share',
        onClick: handleExportSubmit,
        disabled: disabledShare()
      }}>
      <div className='col content'>
        <TextInput
          label='Recipient(s): '
          type='text'
          required
          value={state.recipents}
          onChange={e => setState((_) => ({ ..._, recipents: e.target.value }))} />
        <div className='col hints'>
          <h6 className='f9 reg c00045 line-22'>Use commas “,” to separate multiple email addresses</h6>
        </div>
        <TextInput
          label='Credential: '
          type='text'
          required
          value={state.credentials}
          onChange={e => setState((_) => ({ ..._, credentials: e.target.value }))} />
        <div className='col hints'>
          <h6 className='f9 reg c00045 line-22'>Document will be exported as Encrypted PDF. </h6>
          <h6 className='f9 reg c00045 line-22'>Use this credential to open the Document. </h6>
          <p className='f9 reg c00045 italic line-22'>Share the credential with anyone who will receive the Document & will need to access it!<br />If the credential is lost then the Document(s) need to be downloaded & shared again (with new credentials)...</p>
        </div>
      </div>
    </FormDialog>
  )
}
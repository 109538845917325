import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Menu, useNavigateBack, Button, DataTable } from "../../components";
import { ArchiveDeleteDialog } from "../DocumentDialogs";
import { docActions, getArchives, getDepartments, getDocTypes, getDocStatusList, getDeleteStatus } from "../../store";
import "./style.scss";

const DocumentColumns = [
  { title: 'Version', dataIndex: 'version', key: 'version', width: '100px', className: 'exo2 f9' },
  { title: 'ID', dataIndex: 'doc_id', key: 'doc_id', width: '100px', className: 'exo2 f9 text-center' },
  { title: 'Document', dataIndex: 'name', key: 'name', width: '42%', render: "link", search: true, className: 'exo2 f9' },
  { title: 'Department', dataIndex: 'department_name', key: 'department_name', width: '10%', search: true, className: 'exo2 f9 text-center' },
  { title: 'Status', dataIndex: 'status', key: 'status', width: '11%', render: "color-code", sort: true, className: 'exo2 f9' },
  { title: 'Classification', dataIndex: 'classification', key: 'clasification', width: '12%', render: "color-code", className: 'exo2 f9' },
  { title: 'Type', dataIndex: 'doc_type', key: 'doc_type', width: '10%', sort: true, className: 'exo2 f9 caps' },
  { title: 'Last Updated', dataIndex: 'updated_at', key: 'updated_at', width: '11%', className: 'exo2 f9' },
]

const Menus = [
  { label: "Unarchived Documents", icon: 'icon-open-folder', menuId: 'unarchive' },
  { isEmpty: true },
  { dialogId: 'delete', label: "Delete", icon: 'icon-delete', color: 'cFE3333' },
]
export const ArchivedDocumentsScreen = () => {
  const dispatch = useDispatch()
  const navigateBack = useNavigateBack();
  const documents = useSelector(getArchives)
  const depts = useSelector(getDepartments)
  const types = useSelector(getDocTypes)
  const status = useSelector(getDocStatusList)
  const deleteStatus = useSelector(getDeleteStatus)
  const [state, setState] = useState({ showDocsMenu: null, showDialog: '', selectedDoc: null })
  useEffect(() => {
    if (Array.isArray(depts) && Array.isArray(types) && Array.isArray(status) && !deleteStatus) {
      dispatch(docActions.fetchDocuments())
    }
  }, [depts, types, status])
  const handleDocsMenu = (e) => {
    setState((_) => ({ ..._, showDocsMenu: e.target }))
  }
  const handleClick = (e, menu) => {
    if (menu.dialogId) {
      setState((_) => ({ ..._, showDocsMenu: null, showDialog: menu.dialogId }))
    } else if (menu.menuId) {
      handleDocsMenu({ target: null })
      dispatch(docActions.unarchiveDocuments())
    }
  }
  const handleDialogClose = () => {
    setState((_) => ({ ..._, showDialog: '', selectedDoc: null }))
  }
  const handleDocumentSelection = (selectedRows) => {
    let row = selectedRows.length === 1 ? selectedRows[0] : null
    setState((_) => ({ ..._, selectedDoc: row }))
  }
  return (
    <div className='col w-100 h-100 o-hide'>
      <div className='row docs-header h-btn'>
        <Button
          font='f6 med'
          className='row btn-back'
          onClick={navigateBack}
          label='Archived Documents' icon="icon-back"
          variant='lite' color='rgba(0, 0, 0, 0.85)' iconColor='c00085' />
        {
          Boolean(state.selectedDoc) &&
          <Button variant='lite' className='btn-menu' icon='icon-dot' onClick={handleDocsMenu} />
        }
        <Menu
          menuItems={Menus}
          anchorEl={state.showDocsMenu}
          onMenuClick={handleClick}
          onClose={() => handleDocsMenu({ currentTarget: null })} />
      </div>
      <div className='col f-rest doc'>
        <DataTable
          noSelection
          Columns={DocumentColumns}
          rows={documents}
          onSelectionChange={handleDocumentSelection} />
      </div>
      {
        state.showDialog === 'delete' &&
        <ArchiveDeleteDialog
          isDelete
          document={state.selectedDoc}
          onClose={handleDialogClose} />
      }
    </div>
  )
}
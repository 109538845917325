import React, { useState } from 'react';
import { FormDialog, AutoComplete } from "../../components";
import { docActions, isDraft, getReviewers } from "../../store";
import { useSelector, useDispatch } from 'react-redux';
import "./style.scss";

export const SendDialog = (props) => {
  const { document } = props;
  const dispatch = useDispatch()
  const [state, setState] = useState({ user: '' })
  const isDocDraft = isDraft(document)
  const reviewers = useSelector(getReviewers)
  const handleSendSubmit = (e) => {
    const body = { document_id: document.id };
    if (isDocDraft) {
      body.reviewer_id = state.user.id;
    } else {
      body.approver_id = state.user.id;
    }
    dispatch(docActions.sendDocumentRequest(body))
    props.onClose && props.onClose()
    setState((_) => ({ ..._, user: '' }))
  }
  return (
    <FormDialog
      onClose={props.onClose}
      title={`Send for ${isDocDraft ? "Review" : "Approval"}`}
      className="send-dialog"
      rightBtn={{
        label: 'Send',
        onClick: handleSendSubmit,
        disabled: !Boolean(state.user)
      }}
      leftBtn={{
        label: 'Cancel',
        variant: 'lite',
        color: '#0133CC',
        onClick: props.onClose,
      }}>
      <div className='col content'>
        <AutoComplete
          label={isDocDraft ? "Reviewer" : "Approver"}
          required
          value={state.user}
          showColon
          placeholder={`Search ${isDocDraft ? "Reviewer" : "Approver"}`}
          onChange={(e) => setState((_) => ({ ..._, user: e.target.value }))}
          options={reviewers}
        />
      </div>
    </FormDialog>
  )
}
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { docActions, getCurrentUser, getDateInFormat } from "../../store";
import { FormDialog, NMCKEditor } from "../../components";
import { Radio } from 'antd';
import "./style.scss";

export const ChangeHistoryDialog = (props) => {
  const { document } = props;
  const dispatch = useDispatch()
  const ref = useRef();
  const [state, setState] = useState({ disableSave: true, type: 'minor' })
  const { _name, designation, department_name } = useSelector(getCurrentUser);

  const handleSaveWithUpdate = () => {
    const editor = ref.current;
    if (editor) {
      const text = editor.document.getBody().getText();
      props.onSave && props.onSave(editor.getData(), state.type);
    }
  }
  const handleCKChange = () => {
    const editor = ref.current;
    if (editor) {
      const text = editor.document.getBody().getText();
      let disable = text === '' || text === '\n'
      setState((_) => ({ ..._, disableSave: disable }))
    }
  }
  const handkleDiscord = () => {
    const editor = ref.current;
    if (editor) {
      editor.setData('')
    }
  }
  const handleType = (e) => {
    setState((_) => ({ ..._, type: e.target.value }))
  }
  return (
    <FormDialog
      titleClass='c238787 bold exo2'
      title='Update Change History'
      className='search-var update-history'
      actions={[
        { label: "Discard", variant: 'lite', color: '#FE3333', onClick: handkleDiscord },
        { label: "Cancel", variant: 'lite', color: '#0133CC', onClick: props.onClose },
        {
          label: "Save", onClick: handleSaveWithUpdate,
          disabled: state.disableSave
        }
      ]}>
      <div className='col content'>
        <div className='row w-100 font-input text-input'>
          <label className='f9 c00085 label'>Responsible : </label>
          <div className='f9 input row f-rest'><span className='bold'>{_name}</span>, {designation} - {department_name}</div>
        </div>
        <div className='row w-100 h-btn'>
          <div className='row font-input text-input'>
            <label className='f9 c00085 label'>Date : </label>
            <div className='f9 input row f-rest bold'>{getDateInFormat()}</div>
          </div>
          <div className='row font-input text-input h-end'>
            <label className='f9 c00085 label'>Version : </label>
            <div className='f9 row f-rest input bold'>{document.version}</div>
          </div>
        </div>
        {/* <div className='row w-100 font-input text-input'>
          <label className='f9 c00085 label'>Type : </label>
          <div>
            <Radio.Group onChange={handleType} value={state.type}>
              <Radio value='minor'>Minor</Radio>
              <Radio value="major">Major</Radio>
            </Radio.Group>
          </div>
        </div> */}
        <div className='col f-rest font-input text-input'>
          <label className='f9 c00085 label' style={{ marginBottom: '8px' }}>Description : </label>
          <NMCKEditor
            onChange={handleCKChange}
            onInstanceReady={(e) => ref.current = e.editor} />
        </div>
      </div>
    </FormDialog>
  )
}
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, ProfilePic, AutoComplete } from "../../components";
import { docActions, getDocumentComments, axios } from "../../store";
import { Detector } from "react-detect-offline";

const FilterOptions = [
  { id: 'all', label: 'All' },
  { id: 'resolved', label: 'Resolved' },
  { id: 'non-resolved', label: 'Unresolved' },
]

const CommentCard = (props) => {
  const dispatch = useDispatch()
  const { creator, mark_as_resolved, updated_at, comment, documentId } = props
  const handleResolve = () => {
    dispatch(docActions.updateCommentResolution({ documentId, commentId: props.id }))
  }
  return (
    <div className='row comment w-100 v-start'>
      <ProfilePic className='profile-pic' />
      <div className='col f-rest '>
        <div className='row h-btn user'>
          <h6 className='reg f10 c00045'>{creator.firstname} {creator.lastname}</h6>
          <span className='reg f10 cBFBFBF'>{updated_at}</span>
        </div>
        <p className='comment-text f9 line-22'>{comment}</p>
        {
          mark_as_resolved ?
            <div className='row v-ctr f10 c44bb44 marked'>
              <i className='icon-check-o icon' />
              <span>Resolved</span>
            </div>
            :
            <Button onClick={handleResolve} label="Mark as Resolved" className='btn-mark txt-left' color='#0133CC' variant='lite' font="f10" />
        }
      </div>
    </div>
  )
}
export const CommentSection = (props) => {
  const dispatch = useDispatch()
  const { documentId, show, readOnly } = props
  const [state, setState] = useState({ comment: '', filter: { ...FilterOptions[2] } })
  const comments = useSelector(getDocumentComments.bind(null, documentId))
  useEffect(() => {
    dispatch(docActions.fetchDocComments({ id: documentId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const addComment = () => {
    const body = { document_id: documentId, comment: { comment: state.comment } }
    dispatch(docActions.addDocComment(body))
    setState((_) => ({ ..._, comment: '' }))
  }
  const getFilteredComments = () => {
    if (state.filter.id === 'all') {
      return comments
    }
    let res = comments.filter((comment) => {
      const { mark_as_resolved } = comment;
      return (
        (state.filter.id === FilterOptions[1].id && mark_as_resolved) ||
        (state.filter.id === FilterOptions[2].id && !mark_as_resolved)
      )
    });
    console.log('res', res);
    return res
  }
  const handleFilter = (e) => {
    setState((_) => ({ ..._, filter: e.target.value }))
  }
  return (
    <div className={`col h-100 comment-sec o-hide ${show && 'show'}`}>
      {
        readOnly && comments.length === 0 ?
          <div className='f10 col v-ctr h-ctr w-100'>
            No Comments
          </div>
          :
          <React.Fragment>
            <div className='row h-btn'>
              <h6 className='f8 reg c00085 line-22'>Comments</h6>
              <AutoComplete label="Filter By" className='filter' value={state.filter} options={FilterOptions} onChange={handleFilter} />
            </div>
            {
              !readOnly &&
              <div className='row w-100 v-start new-comment'>
                <ProfilePic className='profile-pic' />
                <div className='col f-rest textarea-cont'>
                  <textarea
                    maxLength={100} rows={4} onChange={(e) => setState((_) => ({ ..._, comment: e.target.value }))}
                    className='textarea w-100' value={state.comment}>
                  </textarea>
                  <div className='row w-100 h-btn v-start count-row'>
                    <Button onClick={addComment} label='Add Comment' className='btn-add' disabled={state.comment.length === 0} />
                    <h6 className='f9 c00025 reg count line-22'>{state.comment.length}/100</h6>
                  </div>
                </div>
              </div>
            }

            <div className='col f-rest oy-auto'>
              {
                getFilteredComments().map((comment, i) => {
                  return <CommentCard {...comment} documentId={documentId} key={i} />
                })
              }
            </div>
          </React.Fragment>
      }
    </div>
  )
}

export const NoInternetAlert = (props) => {
  return (
    <Detector
      onChange={props.onStateChange}
      polling={{
        enabled: true,
        interval: 10000
      }}
      render={({ online }) => {
        return (
          !online ?
            <div className='col w-100 h-100 no-internet-alert v-ctr h-ctr'>
              <div className='row box'>
                <div className='col v-ctr h-ctr warning'>
                  <i className='icon-warning-icon' />
                </div>
                <div className='col'>
                  <h6 className='cc0000 bold f8 exo2'>Disconnected!</h6>
                  <p className='subtitle f10 c000'>Editing is disabled without an internet connection…<br />Reconnect to the internet to continue.</p>
                </div>
              </div>
            </div>
            : null
        )
      }}
    />
  )
}
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { FormDialog, TextInput } from "../../components";
import { getCurrentUser, getDocumentCredentials } from "../../store";
import "./style.scss";

export const DownloadDocumentDialog = (props) => {
  const user = useSelector(getCurrentUser)
  const [state, setState] = useState({ disableNext: true, withoutEncrypt: false, credentials: getDocumentCredentials(user.ent_org) })
  const handleExportSubmit = (e) => {
    const credentials = state.withoutEncrypt ? '' : state.credentials
    props.onDownload && props.onDownload(credentials)
  }
  return (
    <FormDialog
      onClose={props.onClose}
      title='Download Document(s)'
      className="create-doc export-docs"
      rightBtn={{
        label: 'Download',
        onClick: handleExportSubmit,
        disabled: state.credentials.length === 0
      }}>
      <div className='col content'>
        <TextInput
          label='Credential: '
          type='text'
          value={state.credentials}
          disabled={state.withoutEncrypt}
          onChange={e => setState((_) => ({ ..._, credentials: e.target.value }))} />
        <TextInput
          text='Export without Encryption'
          type='checkbox'
          value={state.withoutEncrypt}
          onChange={e => setState((_) => ({ ..._, withoutEncrypt: e.target.checked }))} />
        <div className='col hints'>
          <h6 className='f9 reg c00045 line-22'>Document will be exported as Encrypted PDF. </h6>
          <h6 className='f9 reg c00045 line-22'>Use this credential to open the Document. </h6>
          <p className='f9 reg c00045 italic line-22'>Share the credential with anyone who will receive the Document & will need to access it!<br />If the credential is lost then the Document(s) need to be downloaded & shared again (with new credentials)...</p>
          <h6 className='f9 reg cFE3333 line-22'>Caution! Exporting unencrypted documents is NOT recommended. </h6>
        </div>
      </div>
    </FormDialog>
  )
}
import React, { useState, useEffect, useRef } from 'react';
import { Button, OvalLoading, FormDialog, DocumentEditor, TransparentDialog, PDFViewer } from "../../components";
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { getCurrentUser, getDocumentById, docActions, getCurrentDoc, getDepartments, getDocTypes, getDocStatusList, isArchived, isApproved, isReviewed, isDraft, isViewer, isPublished, getDownloadStatus, getShareStatus } from "../../store";
import { useNavigateBack, Menu, Dropdown } from "../../components";
import { CommentSection, NoInternetAlert } from "./Components";
import { ShareDocumentDialog, CreateDocumentDialog, ArchiveDeleteDialog, SendDialog, ChangeHistoryDialog, DownloadDocumentDialog } from "../DocumentDialogs";
// import { DocumentEditor } from "./Editor";
import "./style.scss";
import moment from 'moment';

const CommonMenus = [
  { isEmpty: true },
  { dialogId: 'archive', label: "Archive", icon: 'icon-open-folder', color: 'cFE3333' },
  { dialogId: 'delete', label: "Delete", icon: 'icon-delete', color: 'cFE3333' },
]
const UnarchiveMenus = [
  { dialogId: 'unarchive', label: "Unarchive", icon: 'icon-open-folder', color: 'cFE3333' },
  { dialogId: 'delete', label: "Delete", icon: 'icon-delete', color: 'cFE3333' },
]
const ShowCommentMenu = { label: "Show Comments", icon: 'icon-open-eye', primary: true, menuId: 'comment' }
const HideCommentMenu = { label: "Hide Comments", icon: 'icon-close-eye', primary: true, menuId: 'comment' }
const ViewMenus = [
  { dialogId: 'download', label: "Download", icon: 'icon-download', primary: true },
  { dialogId: 'share', label: "Share", icon: 'icon-share', primary: true },
]
const Modes = [
  { label: 'Viewing', icon: 'icon-open-eye', value: 'view' },
  { label: 'Editing', icon: 'icon-edit-pencil', value: 'edit' },
]
const ZoomLevels = [
  { label: '50%', value: 50 },
  { label: '100%', value: 100 },
  { label: '125%', value: 125 },
  { label: '150%', value: 150 },
  { label: 'Fit', value: 'fit' },
]

const getMenus = (isCommentOpen, document, user, limitedAccess) => {
  let menus = [], actionMenu;
  if ((!isViewer(user) || limitedAccess) && document && !isArchived(document)) {
    const { approver_id, reviewer_id } = document;
    if (isReviewed(document)) {
      if (approver_id === user.id) {
        actionMenu = { dialogId: 'status-update', label: "Approve", icon: 'icon-send', primary: true, status: 'approved' };
      } else {
        actionMenu = { dialogId: 'status-send', label: "Send for Approval", icon: 'icon-send', primary: true }
      }
    } else if (isDraft(document)) {
      if (reviewer_id === user.id) {
        actionMenu = { dialogId: 'status-update', label: "Mark as Reviewed", icon: 'icon-send', primary: true, status: 'reviewed' };
      } else {
        actionMenu = { dialogId: 'status-send', label: "Send for Review", icon: 'icon-send', primary: true }
      }
    } else if (isApproved(document) && !isViewer(user)) {
      actionMenu = { dialogId: 'status-update', label: "Publish", icon: 'icon-send', primary: true, status: 'published' };
    }
    menus = [...ViewMenus];
    // actionMenu = { dialogId: 'status-send', label: "Send for Review", icon: 'icon-send', primary: true }
  }
  if (actionMenu) {
    menus.unshift(actionMenu)
  }
  menus.push(isCommentOpen ? HideCommentMenu : ShowCommentMenu);
  if (!isViewer(user)) {
    menus = menus.concat(isArchived(document) ? UnarchiveMenus : CommonMenus)
  }
  return menus
}

export const DocumentEditorScreen = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sectionEditors = useRef(null);
  const dataRef = useRef(null)
  const depts = useSelector(getDepartments)
  const types = useSelector(getDocTypes)
  const status = useSelector(getDocStatusList)
  const [state, setState] = useState({ zoomLevel: ZoomLevels[1].value, mode: 'view', disabledUnSaved: true, show: false, menuTarget: null, toggleCommentSec: false, showDialog: '', waitTillDownload: '' })
  const inEditMode = state.mode === 'edit'
  const { documentId } = useParams();
  const currentDoc = useSelector(getCurrentDoc)
  const user = useSelector(getCurrentUser);
  const downloadStatus = useSelector(getDownloadStatus);
  const shareStatus = useSelector(getShareStatus);
  const [searchParams, setSearchParams] = useSearchParams();
  const document = useSelector(getDocumentById.bind(null, isViewer(user), documentId))
  useEffect(() => {
    if (Array.isArray(depts) && Array.isArray(types) && Array.isArray(status)) {
      dispatch(docActions.fetchDocument({ documentId }))
    }
  }, [depts, types, status]);
  useEffect(() => {
    if (currentDoc) {
      if (currentDoc === 'NOTFOUND') {
        navigate('/page-not-found')
      } else {
        setState((_) => ({ ..._, show: true }));
      }
      handleActionsFromURL();
    } else {
      setState((_) => ({ ..._, show: false }));
    }
  }, [currentDoc]);
  useEffect(() => {
    console.log(downloadStatus, shareStatus, state.waitTillDownload);
    if ((downloadStatus === null && shareStatus === null) && (state.waitTillDownload === "DONT_WAIT" || state.waitTillDownload === 'WAIT')) {
      setState((_) => ({ ..._, waitTillDownload: '', showDialog: '' }))
    }
  }, [downloadStatus, shareStatus]);
  const handleActionsFromURL = () => {
    const paramsObject = {};
    for (const [key, value] of searchParams.entries()) {
      paramsObject[key] = value;
    }
    for (const [key] of searchParams.entries()) {
      searchParams.delete(key)
    }
    setSearchParams(searchParams)
    switch (paramsObject.action) {
      case 'update':
        if (paramsObject.attribute && paramsObject.value && currentDoc) {
          const { attribute, value } = paramsObject
          if (attribute === 'status') {
            const { approver_id, reviewer_id } = currentDoc;
            const body = { status: value }
            if ((isReviewed(body) && reviewer_id === user.id && isDraft(currentDoc)) || (isApproved(body) && approver_id === user.id && isReviewed(currentDoc))) {
              handleMenus(null, { dialogId: 'status-update', status: value })
            }
          }
        }
        break;
    }
  }
  const hasLimitedEditAccess = () => {
    let hasAccess = false;
    if (isViewer(user) && currentDoc && user) {
      let date, sevenDaysLater;
      if (currentDoc.approver_id === user.id) {
        date = currentDoc.approve_date
      } else if (currentDoc.reviewer_id === user.id) {
        date = currentDoc.review_date
      }
      if (date) {
        date = moment(date);
        sevenDaysLater = date.clone().add(7, 'days');;
        hasAccess = moment().isBetween(date, sevenDaysLater, null, '[]');
      }
    }
    return hasAccess;
  }
  const handleMenus = (e, menu) => {
    handleMenuBtn({ target: null })
    if (menu.menuId === 'comment') {
      let timer = setTimeout((_) => {
        clearTimeout(timer)
        setState((_) => ({ ..._, toggleCommentSec: !_.toggleCommentSec }))
      }, 100)
    } else if (menu.dialogId === 'status-update') {
      dispatch(docActions.updateDocumentStatus({ id: currentDoc.id, status: menu.status }))
    } else if (menu.dialogId) {
      setState((_) => ({ ..._, showDialog: menu.dialogId }))
    }
    if (menu.dialogId === 'status-send') {
      dispatch(docActions.fetchReviewers())
    }
  }
  const handleMenuBtn = (e) => {
    if (currentDoc) {
      setState((_) => ({ ..._, menuTarget: e.target }))
    }
  }
  const handleSaveDocument = () => {
    handleMenus(null, { dialogId: 'save' })
  }
  const setDialogModal = (dialogId) => {
    let waitTillDownload = state.waitTillDownload;
    if (state.showDialog === "download" || state.showDialog === "share") {
      dialogId = `loading-${state.showDialog}`;
      waitTillDownload = 'WAIT'
    }
    setState((_) => ({ ..._, showDialog: dialogId, waitTillDownload: waitTillDownload }))
  }
  const handleStatusDialog = (email) => {
    setDialogModal('')
    dispatch(docActions.sendDocumentRequest({ creadentials: email }))
  }
  const handleShare = (credential, emails) => {
    setDialogModal('')
    const id = (currentDoc || document).id;
    // dispatch(docActions.setDownloadStatus({ type: 'share', value: null }))
    dispatch(docActions.shareDocuments({ id: id, credential, emails: emails }))
  }
  const handleDownload = (credentials) => {
    setDialogModal('')
    // dispatch(docActions.setDownloadStatus({ type: 'download', value: null }))
    dispatch(docActions.downloadDocuments({ document: (currentDoc || document), credentials }))
  }
  const handleDiscardDialog = (back) => {
    const showDialog = state.showDialog;
    setDialogModal('')
    if (back) {
      if (showDialog === 'delete' || showDialog === 'unarchive' || showDialog === 'archive') {
        let timer = setTimeout(() => {
          clearTimeout(timer)
          navigate("/D")
        }, 200)
      } else {
        navigate("/D")
      }
    }
  }
  const handleEditorReady = (page, index, sectionConfig, editor) => {
    if (!sectionEditors.current[page]) {
      sectionEditors.current[page] = [];
    }
    sectionEditors.current[page][index] = { ...sectionConfig, editor: editor };
  }
  const handleEditorChange = (e, data) => {
    if (state.disabledUnSaved) {
      setState((_) => ({ ..._, disabledUnSaved: false }))
    }
    if (data) {
      dataRef.current = data
    }
  }
  const handleDiscordChange = () => {
    setState((_) => ({ ..._, mode: 'view', disabledUnSaved: true, showDialog: '' }))
  }
  const handleSaveWithUpdate = (htmlDesc, type) => {
    let document = dataRef.current;
    // if (!currentDoc.presentation_id && currentDoc.presentation) {
    //   document.presentation_id = currentDoc.presentation.id;
    // }
    if (isApproved(currentDoc) || isPublished(currentDoc) || isReviewed(currentDoc)) {
      document.status = 'draft';
      document.approver_id = null;
      document.reviewer_id = null;
    }
    dispatch(docActions.updateDocument({ id: currentDoc.id, document, updateHistory: { summary: htmlDesc, type } }))
    handleDiscordChange()
  }
  const limitedAccess = hasLimitedEditAccess()
  return (
    <div className='col w-100 h-100 o-hide doc-editor'>
      {
        Boolean(state.show) && Boolean(currentDoc) ?
          <React.Fragment>
            <div className='row header h-btn'>
              <div className='row'>
                {
                  Boolean(document || currentDoc) &&
                  <>
                    <Button
                      font='f6 med'
                      className='row btn-back'
                      onClick={() => {
                        if (inEditMode && !state.disabledUnSaved) {
                          return setDialogModal('discord')
                        }
                        navigate("/D")
                      }}
                      label={(currentDoc || document).name} icon="icon-back"
                      variant='lite' color='rgba(0, 0, 0, 0.85)' iconColor='c00085' />
                    <div className={`f10 med row v-ctr h-ctr info-card c00085`}>
                      <span>{(currentDoc || document).department_name.label}</span>
                    </div>
                    <div className={`f10 med row v-ctr h-ctr info-card c00085 caps`}>
                      <span>{(currentDoc || document).doc_type.label}</span>
                    </div>
                    {
                      state.mode === 'edit' &&
                      <Button icon='icon-edit-pencil' variant='lite' onClick={() => setDialogModal('edit')} />
                    }
                  </>
                }
              </div>
              <div className='row'>
                {
                  Boolean(currentDoc.attachments.length == 0) &&
                  <Dropdown
                    onChange={(e) => {
                      setState((_) => ({ ..._, zoomLevel: e.target.value }))
                    }}
                    name='zoom' caretClassName='c0033CC' className='mode-select zoom'
                    primary value={state.zoomLevel} options={ZoomLevels} />
                }
                {
                  inEditMode ?
                    <Button
                      disabled={state.disabledUnSaved || state.isOffline}
                      font='f7 med' label='Unsaved' color='#FE3333'
                      className='row v-ctr h-ctr btn-unsaved' icon="icon-save"
                      variant='lite' iconColor='c00085' onClick={handleSaveDocument} />
                    : Boolean((currentDoc || document) && (currentDoc || document).attachments.length === 0) && !isArchived((document || currentDoc)) && !isViewer(user) ?
                      <Dropdown
                        onChange={(e) => {
                          sectionEditors.current = [];
                          setState((_) => ({ ..._, mode: e.target.value }))
                        }}
                        name='mode' caretClassName='c0033CC' className='mode-select'
                        primary value={state.mode} options={Modes} />
                      : null
                }
                {
                  Boolean(currentDoc) && !inEditMode && (limitedAccess || !isViewer(user)) &&
                  <Button className='col v-ctr h-ctr dot-menu btn-menu' icon="f9 icon-dot c00085" variant='lite' onClick={handleMenuBtn} />
                }

                <Menu
                  primary
                  anchorEl={state.menuTarget}
                  onMenuClick={handleMenus}
                  menuItems={getMenus(state.toggleCommentSec, currentDoc, user, limitedAccess)}
                  onClose={() => handleMenuBtn({ target: null })} />
              </div>
            </div>
            <div className='col f-rest o-hide '>
              <div className='row w-100 o-hide h-100 v-start o-hide'>
                <div className='col f-rest document-sec h-100 o-hide '>
                  {
                    Boolean(currentDoc) &&
                    <div className='col w-100 h-100 o-hide'>
                      {
                        Boolean(currentDoc.attachments && currentDoc.attachments.length > 0) ?
                          <PDFViewer
                            {...currentDoc.attachments[0]}
                          />
                          :
                          <DocumentEditor
                            saveData
                            user={user}
                            zoomLevel={state.zoomLevel}
                            document={currentDoc || document}
                            inEditMode={inEditMode}
                            onEditorReady={handleEditorReady}
                            onChange={handleEditorChange}
                          />
                      }
                    </div>
                  }

                </div>
                {
                  !isViewer(user) &&
                  <CommentSection readOnly={isArchived(document || currentDoc) || isPublished(document || currentDoc)} documentId={currentDoc.id} show={state.toggleCommentSec} />
                }
              </div>
              {
                inEditMode &&
                <NoInternetAlert
                  onStateChange={(isOnline) => {
                    setState((_) => ({ ..._, isOffline: !isOnline }))
                  }}
                />
              }
            </div>
          </React.Fragment>
          :
          <OvalLoading />
      }
      <CreateDocumentDialog
        isEdit
        document={document || currentDoc}
        open={state.showDialog === 'edit'}
        onClose={() => setDialogModal('')}
      />
      {
        state.showDialog === 'status-send' &&
        <SendDialog
          document={document || currentDoc}
          onSend={handleStatusDialog}
          onClose={() => setDialogModal('')} />
      }
      {
        (state.showDialog === 'archive' || state.showDialog === 'unarchive' || state.showDialog === 'delete') &&
        <ArchiveDeleteDialog
          user={user}
          isDelete={state.showDialog === 'delete'}
          document={currentDoc}
          onClose={handleDiscardDialog} />
      }
      {
        state.showDialog === 'save' &&
        <ChangeHistoryDialog
          document={currentDoc}
          onSave={handleSaveWithUpdate}
          onClose={() => setDialogModal('')} />
      }
      {
        state.showDialog === 'share' &&
        <ShareDocumentDialog
          onShare={handleShare}
          onClose={() => setDialogModal('')} />
      }
      {
        state.showDialog === 'download' &&
        <DownloadDocumentDialog
          onDownload={handleDownload}
          onClose={() => setDialogModal('')} />
      }
      {
        state.showDialog === 'discord' &&
        <FormDialog
          title='Discard Changes?'
          className='discard-modal'
          titleClass='cFE3333 med'
          onClose={handleDiscardDialog.bind(null, false)}
          rightBtn={{ label: "Discard", className: 'bgCFE3333', onClick: handleDiscordChange }}
          leftBtn={{ label: "Cancel", color: '#0033CC', variant: 'lite', onClick: handleDiscardDialog.bind(null, false) }} />
      }
      {/*
        (state.showDialog === 'loading-download' || state.showDialog === 'loading-share') &&
        <TransparentDialog containerClassName="download-overlay">
          <div className='col'>
            <OvalLoading
              isWhite
              messageClassName="f4 cFFF"
              message={`${state.showDialog === 'loading-download' ? 'Downloading' : 'Sharing'} the PDF, Please Wait...`} />
            {
              state.waitTillDownload !== 'DONT_WAIT' &&
              <div className='row h-ctr'>
                <Button label="Run in background" onClick={() =>
                  setState((_) => ({ ..._, showDialog: "", waitTillDownload: "DONT_WAIT" }))
                } />
              </div>
            }
          </div>
        </TransparentDialog>
          */}
    </div>
  )
}
import "./saga";
import moment from "moment";
import { encode, decode } from "base-64";
export * from "./slice";
export * from "./endpoints";
const getDocState = (state) => state.document;

export const getDepartments = (state) => {
  const _state = getDocState(state);
  return _state.departments
}
export const getDocTemplates = (state) => {
  const _state = getDocState(state);
  return _state.docTemplates
}
export const getDocClassifications = (state) => {
  const _state = getDocState(state);
  return _state.docClassfications
}
export const getDocTypes = (state) => {
  const _state = getDocState(state);
  return _state.docTypes
}
export const getDocStatusList = (state) => {
  const _state = getDocState(state);
  return _state.docStatusList
}
export const getDocStatusListNonArchive = (state) => {
  let _ = getDocStatusList(state), statusList = [];
  if (Array.isArray(_) && _.length > 0) {
    statusList = [..._];
    let index = statusList.findIndex((_) => _.id === 'archived');
    if (index > -1) {
      statusList.splice(index, 1)
    }
  }
  return statusList
}
export const getCurrentAPI = (state) => {
  const _state = getDocState(state);
  return _state.currentAPI
}
export const getDocStats = (state) => {
  const _state = getDocState(state);
  return _state.stats
}
export const getStatusStats = (state) => {
  const stats = getDocStats(state)
  return stats && stats.status_wise
}
export const getDeptStatusStats = (state) => {
  const stats = getDocStats(state)
  const list = [];
  let depts = getDepartments(state), statusList = getDocStatusList(state)
  if (stats && stats.department_status && Array.isArray(depts) && Array.isArray(statusList)) {
    let overall = { overall: 0, title: "Overall" }
    for (let i = 0; i < depts.length; i++) {
      const dept = depts[i];
      let row = { title: dept.label, overall: 0 }
      let deptVal = stats.department_status[dept.id]
      for (let j = 0; j < statusList.length; j++) {
        const status = statusList[j].id;
        if (status !== 'archived') {
          row[status] = deptVal ? (deptVal[status] || 0) : 0;
          row.overall += row[status]
          overall.overall += row[status]
          overall[status] = (overall[status] || 0) + row[status]
        }
      }
      list.push(row)
    }
    list.unshift(overall)
  }
  return list
}
export const getTypeStatusStats = (state) => {
  const stats = getDocStats(state)
  const list = [];
  let types = getDocTypes(state), statusList = getDocStatusList(state)
  if (stats && stats.type_status && Array.isArray(types) && Array.isArray(statusList)) {
    let overall = { overall: 0, title: "Overall" }
    for (let i = 0; i < types.length; i++) {
      const type = types[i]
      let row = { title: type.label, overall: 0 }
      let typeVal = stats.type_status[type.id]
      for (let j = 0; j < statusList.length; j++) {
        const status = statusList[j].id
        if (status !== 'archived') {
          row[status] = typeVal ? (typeVal[status] || 0) : 0;
          row.overall += row[status]
          overall.overall += row[status]
          overall[status] = (overall[status] || 0) + row[status]
        }
      }
      list.push(row)
    }
    list.unshift(overall)
  }
  return list
}
export const getDocumentById = (isViewer, documentId, state) => {
  const documents = getDocuments(isViewer, state)
  return (documents || []).find((doc) => doc.id === documentId)
}
export const getCurrentDoc = (state) => {
  return getDocState(state).currentDoc
}
export const getDocumentComments = (documentId, state) => {
  const _state = getDocState(state);
  return _state.comments
}
export const getDocuments = (isViewer, state) => {
  const _state = getDocState(state);
  let documents = _state.documents;
  if (Array.isArray(documents) && isViewer) {
    documents = documents.filter((doc) => isViewer && isPublished(doc));
  }
  return documents;
}
export const getArchives = (state) => {
  const _state = getDocState(state);
  return _state.archives
}
export const getBlueprints = (state) => {
  const _state = getDocState(state);
  return _state.blueprints
}
export const getNewDocumentsIds = (state) => {
  const _state = getDocState(state);
  return _state.newDocumentIds
}
export const isArchived = (document) => {
  let status = document && document.status;
  status = status && (typeof status === "object") ? status.id : status
  return status === 'archived'
}
export const isReviewed = (document) => {
  let status = document && document.status;
  status = (typeof status === "object") ? status.id : status
  return status === 'reviewed'
}
export const isApproved = (document) => {
  let status = document && document.status;
  status = (typeof status === "object") ? status.id : status
  return status === 'approved'
}
export const isPublished = (document) => {
  let status = document && document.status;
  status = (typeof status === "object") ? status.id : status
  return status === 'published'
}
export const isDraft = (document) => {
  let status = document && document.status;
  status = (typeof status === "object") ? status.id : status
  return status === 'draft'
}
export const getReviewers = (state) => {
  const _state = getDocState(state);
  return _state.reviewers
}
export const getDeleteStatus = (state) => {
  const _state = getDocState(state);
  return _state.deleteInProgress;
}
export const getDocumentCredentials = (org) => {
  let password = String(org.brandname);
  password = password.replace(/[aeiou]/gi, '');
  password = password.toUpperCase();
  if (password.length < 4) {
    password += new Array(4 - password.length).fill('Z').join("");
  } else {
    password = password.slice(0, 4);
  }
  password += moment().format('YYMM');
  password += Math.floor(Math.random() * 90) + 10;
  return 'Document@' + password
}
export const getActivePresentation = (state) => {
  const _state = getDocState(state);
  return _state.activePresentation;
}
export const encyptDataObject = (iData) => {
  let parsed = JSON.stringify(iData);
  parsed = encode(parsed);
  return parsed;
}
export const decryptDataObject = (iDataString) => {
  let decoded = decode(iDataString);
  decoded = JSON.parse(decoded);
  return decoded;
}
export const getDownloadStatus = (state) => {
  const _state = getDocState(state);
  return _state.downloadStatus
}
export const getShareStatus = (state) => {
  const _state = getDocState(state);
  return _state.shareStatus
}
import { createSlice } from "@reduxjs/toolkit";
import { ReducerRegistry } from "../common";
const initialState = {
  departments: [],
  comments: [],
  documents: null,
  archives: [],
  currentDoc: null,
  newDocumentIds: null,
  activePresentation: null,
  reviewers: [],
  deleteInProgress: false,
  downloadStatus: null,
  shareStatus: null
}
const findDocIndex = (list, id) => {
  return list.findIndex((_) => _.id === id)
}
const docSlice = createSlice({
  name: "document",
  initialState: initialState,
  reducers: {
    fetchDepts: () => { },
    fetchDocTemplates: () => { },
    fetchDocTypes: () => { },
    fetchDocClassifications: () => { },
    fetchDocStatusList: () => { },
    fetchReviewers: () => { },
    createDocFromBlueprint: () => { },
    createDocFromPresentation: () => { },
    updateDocument: () => { },
    createAllDocs: () => { },
    uploadDocuments: () => { },
    downloadDocuments: () => { },
    shareDocuments: () => { },
    archiveDocuments: () => { },
    unarchiveDocuments: () => { },
    deleteDocuments: () => { },
    sendDocumentRequest: () => { },
    updateDocumentStatus: () => { },
    fetchBlueprintsByDeptType: () => { },
    fetchNewDocId: () => { },
    fetchActivePresentation: () => { },
    updateCommentResolution: () => { },
    setCurrentAPI: (state, action) => {
      state.currentAPI = action.payload
    },
    setDepts: (state, action) => {
      state.departments = action.payload
    },
    setDocTemplates: (state, action) => {
      state.docTemplates = action.payload
    },
    setDocClassifications: (state, action) => {
      state.docClassfications = action.payload
    },
    setDocTypes: (state, action) => {
      state.docTypes = action.payload
    },
    setDocStatus: (state, action) => {
      state.docStatusList = action.payload
    },
    fetchDocuments: () => { },
    fetchArchivedDocuments: () => { },
    setDocuments: (state, action) => {
      const { list, archives } = action.payload
      if (list) {
        state.documents = list
      }
      if (archives) {
        state.archives = archives
      }
    },
    fetchDocument: () => { },
    addDocComment: () => { },
    fetchDocComments: () => { },
    setDocComments: (state, action) => {
      state.comments = [...action.payload]
    },
    setCurrentDoc: (state, action) => {
      state.currentDoc = action.payload
    },
    fetchDocStats: () => { },
    setDocStats: (state, action) => {
      state.stats = action.payload
    },
    setBlueprints: (state, action) => {
      state.blueprints = action.payload
    },
    setNewDocumentIds: (state, action) => {
      const { payload } = action
      if (payload === 'clear') {
        state.newDocumentIds = null
      } else {
        let ids = (state.newDocumentIds || {})
        ids[payload.index] = payload.doc_id
        state.newDocumentIds = { ...ids }
      }
    },
    setActivePresentation: (state, action) => {
      state.activePresentation = action.payload;
    },
    setReviewers: (state, action) => {
      state.reviewers = action.payload
    },
    removeBlueprint: (state, action) => {
      const { id } = action.payload;
      let index, documents = [...state.documents],
        archives = [...state.archives];
      index = findDocIndex(documents, id);
      if (index > -1) {
        documents.splice(index, 1);
        state.documents = [...documents];
        return;
      }
      index = findDocIndex(archives, id);
      if (index > -1) {
        archives.splice(index, 1);
        state.archives = [...archives];
        return;
      }
    },
    setDeleteInProgress: (state, action) => {
      state.deleteInProgress = action.payload
    }
  }
})
export const docActions = docSlice.actions;

ReducerRegistry.register("document", docSlice.reducer)